// TODO: Rewrite project to TS (add compiler, types, eslint rules)
// TODO: Use destructurization for actions type constants in all action creators
// TODO: Make data sorting on api side everywhere except it needs explicitly client sort

import "react-image-lightbox/style.css";

import "bootstrap/dist/css/bootstrap.min.css";

import "shards-ui/dist/css/shards.min.css";

import "react-table-6/react-table.css";

import "intro.js/introjs.css";

import "react-toastify/dist/ReactToastify.css";

import "index.scss";

import "polyfills";

import "core-js/features/promise/all-settled";

import * as Sentry from "@sentry/browser";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import { hotjar as Hotjar } from "react-hotjar";
import Utils from "utils/Utils";

const PAGE_RELOAD_TIMEOUT = 1500;

const HOTJAR_ENABLED = false;

(async function() {
  const rootElement = window.document.getElementById("root");

  if (!Utils.checkIsDevMode() && !Utils.checkIsNativePlatform()) {
    const { SENTRY_DSN, HOTJAR_ID, ENV_TYPE } = Utils.getProcessEnvVars();

    try {
      Sentry.init({ dsn: SENTRY_DSN, environment: ENV_TYPE });
    } catch (error) {}
    if (Utils.checkIsProductionEnv() && HOTJAR_ENABLED) {
      try {
        Hotjar.initialize(HOTJAR_ID, null);
      } catch (error) {}
    }
  }

  try {
    const { default: Main } = await import("./Main");

    Main.initApp(rootElement);
  } catch (error) {
    setTimeout(() => { window.location.reload(); }, PAGE_RELOAD_TIMEOUT);
  }
})();

serviceWorkerRegistration.unregister();
