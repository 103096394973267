/* eslint-disable no-magic-numbers */
import Countries from "const/Countries";

export default class Constants {
  static REDUX_DEV_TOOLS_TRACE_LIMIT = 30;

  static PERCENTS_MULTIPLIER = 100;

  static DECIMALS_MULTIPLIER = 10;

  static ONE_HALF = 0.5;

  static CIRCLE_DEGREES = 360;

  static CORRECT_DOCUMENT_ROTATION_DEGREES = 90;

  static TABLE_PAGE_SIZE = 20;

  static TABLE_PAGE_SIZE_MEDIUM = 50;

  static TABLE_PAGE_SIZE_MAXIMUM = 100;

  static DUPLICATES_TABLE_PAGE_SIZE_MAXIMUM = 1000;

  static SEARCH_TEXT_MIN_LENGTH = 3;

  static DEFAULT_DOCUMENT_DUE_DATE_DAYS_OFFSET = 14;

  static WINDOW_RESIZE_CALCULATION_TIMEOUT = 500;

  static NOTIFICATION_AUTO_CLOSE_DELAY = 5 * 1000;

  static EDIT_DOCUMENT_WINDOW_BUTTONS_ENABLE_TIMEOUT = 1000;

  static TEXT_FIELD_DEBOUNCE_TIMEOUT = 1000;

  static DATE_FIELD_DEBOUNCE_TIMEOUT = 3000;

  static MANUAL_RPA_START_COOLDOWN = 3 * 60 * 60 * 1000;

  static LOGIN_PAGE_REFRESH_TIMEOUT = 60 * 1000;

  static GLOBAL_STATS_UPDATE_INTERVAL = 30 * 1000;

  static DATA_LIST_UPDATE_INTERVAL = 30 * 1000;

  static BUSINESSES_DATA_UPDATE_INTERVAL = 60 * 1000;

  static CONTACTS_DATA_UPDATE_INTERVAL = 5 * 60 * 1000;

  static INSTITUTION_LINKS_EXPIRATION_UPDATE_INTERVAL = 10 * 60 * 1000;

  static AUTO_LOGOUT_FOR_TEMP_USER = 60 * 1000;

  static AUTO_PAGE_RELOAD_ON_ERROR_DELAY = 60 * 1000;

  static INTEGRATION_FLOW_PAGE_RELOAD_DELAY = 3000;

  static WINDOW_RESIZE_DEBOUNCE_DELAY = 300;

  static LAST_ASKED_EMAIL_EXPIRATION_DURATION = 30 * 24 * 60 * 60 * 1000;

  static MAX_BUSINESS_SHOW_INTRO_AGE_DAYS = 7 * 24 * 60 * 60 * 1000;

  static MAX_API_SEQUENTIAL_ERRORS = 10;

  static SIMPLIFIED_LAYOUT_MAX_WIDTH = 1200;

  static NEW_UI_SIMPLIFIED_LAYOUT_MAX_WIDTH = 999;

  static SIDE_MENU_WIDTH = 72;

  static SIDE_PANEL_WIDTH = 336;

  static SIDE_PANEL_WIDE_WIDTH = 1120;

  static LAYOUT_MIN_WIDTH = 1010;

  static REDUCED_IMAGE_MAX_DIMENSIONS = 2340; // 200 DPI A4 height

  static DATA_SIMILARITY_COEFFICIENT = 0.8;

  static DESKTOP_LAYOUT_MIN_WIDTH = 1024;

  static SIDE_PANEL_MIN_WIDTH = 256;

  static MENU_FULL_WIDTH_MIN_VIEWPORT = 1600;

  static EMPTY_PLACEHOLDER = "–";

  static UNKNOWN_BANK_CODE = "unknown";

  static VOID_URL = "//:0";

  static GOOGLE_MAPS_SEARCH_URL = "https://google.com/maps/search";

  static QBWC_DEVELOPER_PORTAL_URL = "https://developer.intuit.com";

  static QBWC_INSTALLATION_GUIDE_PATH = "/app/developer/qbdesktop/docs/get-started/get-started-with-quickbooks-web-connector";

  static DEFAULT_NUMBERS_LOCALE = "en-US";

  static DEFAULT_LANGUAGE = "en";

  static DEFAULT_CURRENCY = "USD";

  static MENTION_TRIGGER_CHAR = "@";

  static COMMENTS = "comments";

  static PARENT_FOLDER_RELATIVE_PATH = "..";

  static ACCOUNT_NUMBER_SUBSTRING_LENGTH = 4;

  static ACCOUNT_EXPIRATION_DAYS_WARNING = 7;

  static CZ_NEW_VAT_DATE = "2024-01-01";

  static LANGUAGES = {
    EN: { code: "en", name: "English", defaultCountryCode: Countries.US },
    CS: { code: "cs", name: "Čeština", defaultCountryCode: Countries.CZ }
  };

  static LANGUAGES_ARRAY = Object.values(Constants.LANGUAGES);

  static COLORS = {
    WHITE: "#fff",
    BLACK: "#000",
    MEDIUM_DARK: "#8c96a0",
    POSITIVE: "#17c671",
    NEGATIVE: "#c4173c",
    WARNING: "#ffb400",
    HIGHLIGHT: "#007bff"
  };

  static OLD_VAT_RATES = [21, 15, 10, 0];

  static VAT_RATES = [21, 12, 0];

  static DOCUMENT_FILE_TYPES = {
    PDF: {
      extensions: [".pdf"],
      mimeType: "application/pdf"
    },
    PNG: {
      extensions: [".png"],
      mimeType: "image/png"
    },
    JPG: {
      extensions: [".jpg", ".jpeg"],
      mimeType: "image/jpeg"
    },
    TIFF: {
      extensions: [".tif", ".tiff"],
      mimeType: "image/tiff"
    },
    DOC: {
      extensions: [".doc"],
      mimeType: "application/msword"
    },
    DOCX: {
      extensions: [".docx"],
      mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    },
    XLS: {
      extensions: [".xls"],
      mimeType: "application/vnd.ms-excel"
    },
    XLSX: {
      extensions: [".xlsx"],
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  };

  static LS_KEYS = {
    PREFERRED_LANG: "preferredLang",
    DOWNLOAD_APP_PROMPT: "downloadAppPrompt",
    SELECTED_BUSINESS: "selectedBusiness",
    EXCLUDED_INTROS: "excludedIntros",
    ABRA_FLEXI_BEE_SETTINGS: "abraFlexiBeeSettings",
    LAST_ASKED_BUSINESS_EMAIL: "lastAskedBusinessEmail",
    AUDIT_FILTERS: "auditFilters",
    NEW_BUSINESS_RPA_MODE: "newBusinessRpaMode"
  };

  static HTTP_STATUSES = {
    OK: 200,
    UNAUTHORIZED: 401
  };

  static DATETIME_FORMATS = {
    TIME: "HH:mm",
    DATETIME: "DD.MM.YYYY HH:mm",
    DATETIME_TEXT: "MMM DD, YYYY HH:mm",
    DATE: "DD.MM.YYYY",
    DATE_TEXT: "MMM DD, YYYY",
    DATE_TEXT_EXT: "MMMM DD, YYYY",
    MONTH: "MM",
    MONTH_TEXT: "MMM",
    SHORT_DATE: "DD.MM",
    SHORT_DATE_TEXT: "MMM DD",
    YEAR_AND_MONTH: "YYYY.MM",
    MONTH_AND_YEAR: "MM.YYYY",
    MONTH_AND_YEAR_TEXT: "MMM YYYY",
    MONTH_AND_YEAR_TEXT_EXT: "MMMM YYYY",
    API_DATE: "YYYY-MM-DD",
    API_MONTH_DATE: "YYYY-MM",
    NO_TIMEZONE_FORMAT: "YYYY-MM-DDTHH:mm:ss"
  };

  static DATE_FORMATS = {
    US: "MM/DD/YYYY",
    EU: "DD.MM.YYYY"
  };

  static CLIENT_TYPES = {
    DESKTOP: "desktop",
    MOBILE: "mobile"
  };

  static ENV_TYPES = {
    PRODUCTION: "production",
    STAGING: "staging"
  };

  static NODE_ENV_TYPES = {
    PRODUCTION: "production",
    DEVELOPMENT: "development"
  };

  static AUDIT_SECTIONS = {
    TRANSACTIONS: "transactions"
  };

  static AUDIT_SECTIONS_DATA = {
    TRANSACTIONS: {
      ALL_CATEGORIZED: "allCategorized",
      NO_PAYEE: "noPayee",
      NO_CLASS: "noClass",
      NO_LOCATION: "noLocation",
      NO_PROJECT: "noProject",
      NO_ATTACHMENT: "noAttachment",
      AMOUNT_DEVIATION: "amountDeviation",
      DUPLICATES: "duplicates",
      SIMILAR_PAYEE: "similarPayee",
      POSTED_TO_PARENT: "postedToParent",
      UNUSUAL_CATEGORY: "unusualCategory",
      UNUSUAL_AMOUNT: "unusualAmount",
      UNUSUAL_TAX: "unusualTax"
    }
  };

  static AUDIT_SUBSECTIONS_ROUTES = {
    ALL_CATEGORIZED: "all-categorized",
    NO_PAYEE: "no-payee",
    NO_CLASS: "no-class",
    NO_LOCATION: "no-location",
    NO_PROJECT: "no-project",
    NO_ATTACHMENT: "no-attachment",
    AMOUNT_DEVIATION: "amount-deviation",
    DUPLICATES: "duplicates",
    SIMILAR_PAYEE: "similar-payee",
    POSTED_TO_PARENT: "posted-to-parent",
    UNUSUAL_CATEGORY: "unusual-category",
    UNUSUAL_AMOUNT: "unusual-amount",
    UNUSUAL_TAX: "unusual-tax"
  };

  static AUDIT_SUBSECTION_PATH_TO_NAME = Object.fromEntries(
    Object.entries(Constants.AUDIT_SUBSECTIONS_ROUTES)
      .map(([key, value]) => [value, Constants.AUDIT_SECTIONS_DATA.TRANSACTIONS[key]])
  );

  static AUDIT_AMOUNT_DEVIATION_FILTERS = {
    LESS_THAN: "lessThan",
    LESS_THAN_OR_EQUAL: "lessThanOrEqual",
    GREATER_THAN: "greaterThan",
    GREATER_THAN_OR_EQUAL: "greaterThanOrEqual",
    EQUAL: "equal",
    BETWEEN: "between"
  };

  static EXTRA_COLUMN_MODES = {
    CLASSES: "classes",
    LOCATIONS: "locations",
    PROJECTS: "projects",
    TAX_RATES: "taxRates"
  };

  static PLATFORM_TYPES = {
    IOS: "ios",
    ANDROID: "android"
  };

  static RPA_WORKFLOWS = {
    PARSE_TRANSACTIONS: "parse-transactions",
    MATCH_TRANSACTIONS: "match-transactions",
    RECONCILE_TRANSACTONS: "reconcile-transactions"
  };

  static AUDIT_DEVIATION_DEFAULT_VALUE = 1000;

  static AUDIT_DEVIATION_DEFAULT_MIN_VALUE = 0;

  static NEW_ENTITY_ID = "new";

  static ACTIVE_TASKS_LIMIT = 5;

  static PHONE_INPUT_PATTERN = /^(\+?\d+)?[\s-]?(\(\d+\)|\d+)[\s-]?\d+[\s-]?\d+[\s-]?\d+$/;

  static SMS_NOTIFICATIONS_ALLOWED_COUNTRIES = [
    Countries.AU,
    Countries.CA,
    Countries.GB,
    Countries.US,
    Countries.ZA
  ];

  static KEY_NAMES = {
    BACKSPACE: "Backspace",
    ENTER: "Enter",
    COMMA: ","
  };

  static WORKFLOW_TYPES = {
    ROBOTIC: "robotic",
    REGULAR: "regular"
  };

  static FINE_TUNE_STATUS_FAILED = "FAILED";

  static TRANSACTION_FIELDS_TO_EDIT =
    ["address", "vendorId", "category", "item", "location", "project", "taxRate", "class", "tags"];
}
